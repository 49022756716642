import { useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { PhoneInput } from "react-international-phone";
import OTPInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
import { validateMobile } from "../../utils/helpers";
import { toast } from "sonner";
import axios from "axios";

export const LoginWithPhone = ({
  formData,
  handleInputChange,
  showPassword,
  ipAddress,
  next,
}) => {
  const [otpSent, setOtpSent] = useState(false);
  const [mobile, setMobile] = useState("");
  const [isSendingOtp, setIsSendingOtp] = useState(false);
  const [otp, setotp] = useState("");
  const [isVerifingOtp, setIsVerifingOtp] = useState(false);
  const navigate = useNavigate();

  const handleSendOtp = async (e) => {
    e.preventDefault();
    setIsSendingOtp(true);
    try {
      const isMobileValid = validateMobile(mobile);
      if (!isMobileValid)
        return toast.error("Please enter a valid phone number");

      const sendOtpUrl = `${process.env.REACT_APP_API_URL}/auth/signin-by-phone`;
      const response = await axios.post(sendOtpUrl, {
        mobile,
      });
      console.log(response);
      if (response.status === 200) {
        const { message } = response.data;
        toast.success(message);
        setOtpSent(true);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        if (error.response.status === 404) {
          toast.error(error.response.data.message);
        }
      } else {
        toast.error("something went wrong while sending otp");
      }
    } finally {
      setIsSendingOtp(false);
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    setIsVerifingOtp(true);
    try {
      if (otp.length < 6) return toast.error("OTP must be of 6 digits");
      const verifyOtpUrl = `${process.env.REACT_APP_API_URL}/auth/login-by-phone?origin=${window.location.href}&actionType=SignIn&resetPassword=false`;
      const response = await axios.post(
        verifyOtpUrl,
        {
          mobile,
          otp,
          ipAddress,
        },
        { withCredentials: true },
      );

      if (response.status === 200 && response.data.redirected === true) {
        navigate(`/signup?userId=${response.data.user._id}&redirected=true`);
        return;
      }
      if (response.status === 200) {
        const { message } = response.data;
        toast.success(message);
        window.location.href =
          next || import.meta.env.REACT_APP_DEFAULT_REDIRECT_URL;
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          toast.error(error.response.data.message);
        } else {
          toast.error("something went wrong please try again later");
        }
      }
    } finally {
      setIsVerifingOtp(false);
    }
  };

  return (
    <Row>
      {!otpSent ? (
        <Form onSubmit={handleSendOtp}>
          <Col md={12}>
            <Form.Group className="mb-3">
              <Form.Label>
                Phone <span className="text-danger">*</span>
              </Form.Label>
              <PhoneInput
                defaultCountry="in"
                value={mobile}
                onChange={setMobile}
                placeholder="Enter mobile number"
                required
              />
            </Form.Group>
          </Col>
          <Col md={12} className="invisible">
            <Form.Group className="invisible-phone">
              <Form.Label>
                Password <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                className="shadow-none"
                type={showPassword ? "text" : "password"}
                autoComplete="true"
                placeholder="Enter 6 digit OTP here..."
                aria-describedby="inputGroupPrepend"
                size="small"
              />
            </Form.Group>
          </Col>
          <Col className="d-flex justify-content-between">
            <Form.Group className="mb-3">
              <Form.Check type="checkbox" id="test" label="Remember me" />
            </Form.Group>

            <Form.Group className="mb-3">
              <Link to="/forgot-password" className="text_main_color">
                Forgot password?
              </Link>
            </Form.Group>
          </Col>
          <Col md={12} className="text-center">
            <Button type="submit" className="text-center w-25 customBg">
              {isSendingOtp ? (
                <Spinner size="sm" className="animate-spin" />
              ) : (
                "Send OTP"
              )}
            </Button>
          </Col>
        </Form>
      ) : (
        <Form onSubmit={handleVerifyOtp}>
          <Col md={12}>
            <Form.Group className="mb-3">
              <Form.Label>
                OTP <span className="text-danger">*</span>
              </Form.Label>
              <OTPInput
                className=""
                name="otp"
                value={otp}
                onChange={setotp}
                numInputs={6}
                renderSeparator={<span>-</span>}
                renderInput={(props) => (
                  <input
                    {...props}
                    className="form-control shadow-none w-100"
                  />
                )}
                inputType="tel"
              />
            </Form.Group>
          </Col>
          <Col md={12} className="invisible">
            <Form.Group className="invisible-phone">
              <Form.Label>
                Password <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                className="shadow-none"
                type={showPassword ? "text" : "password"}
                autoComplete="true"
                placeholder="Enter 6 digit OTP here..."
                aria-describedby="inputGroupPrepend"
                size="small"
              />
            </Form.Group>
          </Col>
          <Col className="d-flex justify-content-between">
            <Form.Group className="mb-3">
              <Form.Check type="checkbox" id="test" label="Remember me" />
            </Form.Group>

            <Form.Group className="mb-3">
              <Link to="/forgot-password" className="text_main_color">
                Forgot password?
              </Link>
            </Form.Group>
          </Col>
          <Col md={12} className="text-center">
            <Button type="submit" className="text-center w-25 customBg">
              {isVerifingOtp ? (
                <Spinner size="sm" className="animate-spin" />
              ) : (
                "Verify OTP"
              )}
            </Button>
          </Col>
        </Form>
      )}
    </Row>
  );
};
