import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, Card, Form } from "react-bootstrap";
import { getIp } from "../../utils/helpers.js";
import "react-international-phone/style.css";
import { LoginWithEmail } from "./login-with-email.jsx";
import { LoginWithPhone } from "./login-with-phone.jsx";

const Login = () => {
  const [IsloginEmail, setIsloginEmail] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    phone: "",
    otp: "",
  });
  const [showPasswordMessage, setPasswordMessage] = useState("");
  const [showEmailMessage, setEmailMessage] = useState("");
  const next = new URLSearchParams(window.location.search).get("next");
  const [ipAddress, setIpAddress] = useState(null);

  useEffect(() => {
    const fetchIp = async () => {
      const ip = await getIp();
      setIpAddress(ip);
    };

    fetchIp();
  }, []);

  const handleTabSwitch = (tab) => {
    setIsloginEmail(tab === "email");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmailMessage("");
    }
    if (name === "password") {
      setPasswordMessage("");
    }
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleShowPassword = () => setShowPassword((prev) => !prev);

  return (
    <>
      <Container className="mt-5 mb-5 position-relative z-3">
        <Row className="m-2">
          <Col
            lg={12}
            className="d-flex align-items-center justify-content-center"
          >
            <img
              src="/allheartweb-logo.png"
              alt="Logo"
              className="logo text-center my-5"
              loading="lazy"
            />
          </Col>
        </Row>

        <Row className="d-flex align-items-center justify-content-center">
          <Col lg={6} md={6} sm={11}>
            <Card className="ms-center">
              <Card.Body className="m-3">
                <div className="form-container">
                  <div className="slide-controls">
                    <input
                      type="radio"
                      name="slide"
                      id="login"
                      checked={IsloginEmail}
                      onChange={() => handleTabSwitch("email")}
                    />
                    <input
                      type="radio"
                      name="slide"
                      id="signup"
                      checked={!IsloginEmail}
                      onChange={() => handleTabSwitch("phone")}
                    />
                    <label
                      htmlFor="login"
                      className={`slide lable login ${IsloginEmail ? "active" : ""}`}
                    >
                      Sign In by email
                    </label>
                    <label
                      htmlFor="signup"
                      className={`slide lable signup ${
                        !IsloginEmail ? "active" : ""
                      }`}
                    >
                      Sign In by phone
                    </label>
                    <div
                      className={`slide-tab ${IsloginEmail ? "" : "signup"}`}
                    ></div>
                  </div>
                  <div className="mt-4">
                    {IsloginEmail ? (
                      <LoginWithEmail
                        showPasswordMessage={showPasswordMessage}
                        showEmailMessage={showEmailMessage}
                        formData={formData}
                        handleInputChange={handleInputChange}
                        showPassword={showPassword}
                        handleShowPassword={handleShowPassword}
                        ipAddress={ipAddress}
                        next={next}
                      />
                    ) : (
                      <LoginWithPhone
                        formData={formData}
                        handleInputChange={handleInputChange}
                        showPassword={showPassword}
                        ipAddress={ipAddress}
                        next={next}
                      />
                    )}
                  </div>
                  <Form.Group className="my-4 text-center">
                    Don't have an account?{" "}
                    <Link to="/signup" className="text_main_color">
                      Sign up now!
                    </Link>
                  </Form.Group>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Login;
