import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Col,
  Container,
  Row,
  Button,
  Card,
  Form,
  Spinner,
} from "react-bootstrap";
import { toast } from "sonner";
import { PhoneInput } from "react-international-phone";
import axios, { AxiosError } from "axios";
import OTPInput from "react-otp-input";
import { validateEmail, validateMobile } from "../../utils/helpers";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [IsloginEmail, setIsloginEmail] = useState(true);
  const [otp, setOTP] = useState("");
  const [mobile, setMobile] = useState();
  const [showPhoneMessage, setPhoneMessage] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [verifyingOtp, setVerifiingOtp] = useState(false);
  const [showOtpMessage, setOtpMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleTabSwitch = (tab) => {
    setIsloginEmail(tab === "email");
  };

  const handleRequestForgetPasswordByEmail = async (e) => {
    e.preventDefault();
    setMessage("");
    setLoading(true);
    const ForgotPasswordURL = `${process.env.REACT_APP_API_URL}/auth/reset-password-request`;
    try {
      const isvalidEmail = validateEmail(email);
      if (!isvalidEmail)
        return toast.error("Please enter a valid email address");
      const response = await axios.post(ForgotPasswordURL, { email });

      if (response.status === 200) {
        setMessage("Reset password link sent to your email.");
        toast.success("Reset password link sent to your email");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      } else {
        toast.error(response.data.message || "Something went wrong.");
      }
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error occurred. Please try again later.");
      }
    } finally {
      setLoading(false);
    }
  };

  //TODO: Check this if it is repeted if repeted move it to different file or make a custom hook
  const handleSendOtp = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const isMobileValid = validateMobile(mobile);
      if (!isMobileValid)
        return toast.error("Please enter a valid phone number");

      const sendOtpUrl = `${process.env.REACT_APP_API_URL}/auth/signin-by-phone`;
      const response = await axios.post(sendOtpUrl, {
        mobile,
      });
      if (response.status === 200) {
        toast.success("Otp Sent Successflly");
        setOtpSent(true);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        if (error.response.status === 404) {
          toast.error(error.response.data.message || "error while sending otp");
        }
      } else {
        toast.error("error while sending otp");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    try {
      setVerifiingOtp(true);
      if (otp.length < 6) return toast.error("OTP must be of 6 digits");

      const verifyOtpUrl = `${process.env.REACT_APP_API_URL}/auth/login-by-phone?origin=${window.location.href}&actionType=OtpVerification&resetPassword=true`;
      const response = await axios.post(verifyOtpUrl, {
        mobile,
        otp,
      });

      if (response.status === 200) {
        toast.success("Otp verified successfully");
        navigate(`/reset-password/${response.data.resetToken}`);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          toast.error(error.response.data.message);
        } else {
          toast.error("something went wrong please try again later");
        }
      }
    } finally {
      setVerifiingOtp(false);
    }
  };

  return (
    <Container className="mt-5 mb-5 position-relative z-3">
      <Row className="m-2">
        <Col
          lg={12}
          className="d-flex align-items-center justify-content-center"
        >
          <img
            src="/allheartweb-logo.png"
            alt="Logo"
            className="logo text-center my-5"
            loading="lazy"
          />
        </Col>
      </Row>

      <Row className="d-flex align-items-center justify-content-center">
        <Col lg={6} md={6} sm={11}>
          <Card className="ms-center">
            <h2 className="text-center mt-4">Forgot Password</h2>
            <Card.Body className="m-3">
              <div className="form-container">
                <div className="slide-controls">
                  <input
                    type="radio"
                    name="slide"
                    id="login"
                    checked={IsloginEmail}
                    onChange={() => handleTabSwitch("email")}
                  />
                  <input
                    type="radio"
                    name="slide"
                    id="signup"
                    checked={!IsloginEmail}
                    onChange={() => handleTabSwitch("phone")}
                  />
                  <label
                    htmlFor="login"
                    className={`slide lable login ${IsloginEmail ? "active" : ""}`}
                  >
                    Reset via email
                  </label>
                  <label
                    htmlFor="signup"
                    className={`slide lable signup ${!IsloginEmail ? "active" : ""}`}
                  >
                    Reset via phone
                  </label>
                  <div
                    className={`slide-tab ${IsloginEmail ? "" : "signup"}`}
                  ></div>
                </div>
                {IsloginEmail ? (
                  <Form
                    onSubmit={handleRequestForgetPasswordByEmail}
                    className="mt-4"
                  >
                    <Form.Group className="mb-3">
                      <Form.Label>
                        {" "}
                        Email <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="shadow-none custom-placeholder ForgotPassword-input"
                        size="small"
                        type="text"
                        placeholder="Enter your email..."
                        required
                      />
                      {/* {showEmailMessage ? (
                      <p className="mt-2 text-danger"> {showEmailMessage} </p>
                    ) : (
                      <p className="mt-2 text-danger"> </p>
                    )} */}
                    </Form.Group>
                    <div className="d-flex align-items-center justify-content-center mt-5">
                      <Button
                        type="submit"
                        className="text-center w-25 customBg"
                        disabled={loading}
                      >
                        {loading ? (
                          <Spinner size="sm" className="animate-spin" />
                        ) : (
                          "Send Link"
                        )}
                      </Button>
                    </div>
                  </Form>
                ) : otpSent ? (
                  <Col md={12} className="mt-4">
                    <Form.Group className="mb-1">
                      <Form.Label>
                        OTP <span className="text-danger">*</span>
                      </Form.Label>
                      <OTPInput
                        className=""
                        name="otp"
                        value={otp}
                        onChange={setOTP}
                        numInputs={6}
                        renderSeparator={<span>-</span>}
                        renderInput={(props) => (
                          <input
                            {...props}
                            className="form-control shadow-none w-100"
                          />
                        )}
                        inputType="tel"
                      />
                      {showOtpMessage ? (
                        <p className="mt-2 text-danger"> {showOtpMessage} </p>
                      ) : (
                        <p className="mt-2 text-danger"> </p>
                      )}
                    </Form.Group>
                    <div className="d-flex align-items-center justify-content-center mt-5">
                      <Button
                        type="submit"
                        className="text-center w-25 customBg"
                        onClick={handleVerifyOtp}
                      >
                        {verifyingOtp ? (
                          <Spinner size="sm" className="animate-spin" />
                        ) : (
                          "Verify Otp"
                        )}
                      </Button>
                    </div>
                  </Col>
                ) : (
                  <Form className="mt-4">
                    <Col md={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          Phone <span className="text-danger">*</span>
                        </Form.Label>
                        <PhoneInput
                          defaultCountry="in"
                          value={mobile}
                          onChange={setMobile}
                          placeholder="Enter mobile number"
                          required
                        />
                        {showPhoneMessage ? (
                          <p className="mt-2 text-danger">
                            {" "}
                            {showPhoneMessage}{" "}
                          </p>
                        ) : (
                          <p className="mt-2 text-danger"> </p>
                        )}
                      </Form.Group>
                    </Col>
                    <div className="d-flex align-items-center justify-content-center mt-5">
                      <Button
                        type="submit"
                        className="text-center w-25 customBg"
                        disabled={loading}
                        onClick={handleSendOtp}
                      >
                        {loading ? (
                          <Spinner size="sm" className="animate-spin" />
                        ) : (
                          "Send Otp"
                        )}
                      </Button>
                    </div>
                  </Form>
                )}
              </div>
              <Form.Group className="mt-5 text-center">
                Remembered your password?{" "}
                <Link to="/" className="text_main_color">
                  Log in now!
                </Link>
              </Form.Group>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

//TODO: remove these styles if not used
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#f9f9f9",
    fontFamily: "Arial, sans-serif",
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
    color: "#333",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "300px",
    gap: "15px",
  },
  input: {
    padding: "10px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  button: {
    padding: "10px",
    fontSize: "16px",
    backgroundColor: "#007BFF",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  buttonHover: {
    backgroundColor: "#0056b3",
  },
  message: {
    marginTop: "20px",
    fontSize: "14px",
    color: "#555",
  },
  logo: { width: "300px", marginBottom: "20px" },
};

export default ForgotPassword;
