import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { Toaster } from "sonner";
import "react-international-phone/style.css";

import Login from "./components/login/login.jsx";
import ForgotPassword from "./components/forgotPassword/forgot-password.jsx";

// const Login = React.lazy(() => import("./components/login/login.jsx"));
// const ForgotPassword = React.lazy(
//   () => import("./components/forgotPassword/forgot-password.jsx"),
// );
const ResetPassword = React.lazy(() => import("./components/ResetPassword"));
const MultiStepSignup = React.lazy(
  () => import("./components/multi-step-signup/multi-step-signup.jsx"),
);
// import ResetPassword from "./components/ResetPassword";
// import MultiStepSignup from "./components/multi-step-signup/multi-step-signup.jsx";

const LoadingFallback = () => (
  <div className="d-flex justify-content-center align-items-center vh-100">
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  </div>
);

const App = () => {
  return (
    <BrowserRouter>
      <Toaster position="top-right" expand={true} richColors />
      <Suspense fallback={<LoadingFallback />}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/reset-password/:resetToken"
            element={<ResetPassword />}
          />
          <Route path="/signup" element={<MultiStepSignup />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
