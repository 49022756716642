import axios from "axios";
import { useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "sonner";
import { validatePassword } from "../../utils/helpers";

export const LoginWithEmail = ({
  showEmailMessage,
  showPasswordMessage,
  formData,
  handleInputChange,
  showPassword,
  ipAddress,
  next,
}) => {
  const [loading, setLoading] = useState(false);

  const handleLoginByEmail = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      var email = formData.email;
      var password = formData.password;

      const isPasswordValid = validatePassword(password);
      if (!isPasswordValid)
        return toast.error("Password must be atleast 8 characters long");
      const verifyOtpUrl = `${process.env.REACT_APP_API_URL}/auth/login-by-email?origin=${window.location.href}&actionType=SignIn`;
      const response = await axios.post(
        verifyOtpUrl,
        {
          email,
          password,
          ipAddress,
        },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      if (response.status === 200) {
        window.location.href =
          next || process.env.REACT_APP_DEFAULT_REDIRECT_URL;
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        error.response ? error.response.data.message : "Login failed",
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row>
      <Form onSubmit={handleLoginByEmail}>
        <Col md={12}>
          <Form.Group className="mb-3">
            <Form.Label>
              {" "}
              Email <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="shadow-none custom-placeholder"
              size="small"
              type="text"
              placeholder="Enter your email..."
              required
            />
            {showEmailMessage ? (
              <p className="mt-2 text-danger"> {showEmailMessage} </p>
            ) : (
              <p className="mt-2 text-danger"> </p>
            )}
          </Form.Group>
        </Col>

        <Col md={12}>
          <Form.Group className="mb-3">
            <Form.Label>
              Password <span className="text-danger">*</span>
            </Form.Label>
            <div className="position-relative w-100 ">
              <Form.Control
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                className="shadow-none custom-placeholder"
                type={showPassword ? "text" : "password"}
                autoComplete="true"
                placeholder="Enter your password"
                aria-describedby="inputGroupPrepend"
                size="small"
                required
              />
            </div>

            {showPasswordMessage ? (
              <p className="mt-2 text-danger"> {showPasswordMessage} </p>
            ) : (
              <p className="mt-2 text-danger"> </p>
            )}
          </Form.Group>
        </Col>
        <Col className="d-flex justify-content-between">
          <Form.Group className="mb-3">
            <Form.Check type="checkbox" id="test" label="Remember me" />
          </Form.Group>

          <Form.Group className="mb-3">
            <Link to="/forgot-password" className="text_main_color">
              Forgot password?
            </Link>
          </Form.Group>
        </Col>
        <Col md={12} className="text-center">
          <Button
            type="submit"
            className="text-center w-25 customBg"
            disabled={loading}
          >
            {loading ? (
              <Spinner size="sm" className="animate-spin" />
            ) : (
              "Sign In"
            )}
          </Button>
        </Col>
      </Form>
    </Row>
  );
};
