import axios from "axios";

export const getIp = async () => {
  try {
    const response = await axios.get("https://api.ipify.org");
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const validateEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email.trim());
};

export const validatePassword = (password) => {
  if (password.trim().length < 8) {
    return false;
  }
  return true;
};

export const validateMobile = (mobile) => {
  const mobileRegex = /^[+\-\s0-9]{10,20}$/;
  return mobileRegex.test(mobile.trim());
};

export const getAllCountries = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/get-all-countries`,
    );
    if (response.status === 200) {
      return response.data.AllCountries;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getStatesByCountryIsoCode = async (countryIsoCode) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/get-states-by-country/${countryIsoCode}`,
    );
    if (response.status === 200) {
      return response.data.states;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getCitiesByCountryAndState = async (countryCode, stateIsoCode) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/get-cities-by-state?countryCode=${countryCode}&stateIsoCode=${stateIsoCode}`,
    );
    if (response.status === 200) {
      return response.data.cities;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};
